.user-management-balance {

	.wallet-content-tables {
		padding: 0;
	}

	.coin {
		display: flex;
	}

	.td--right {
		p {

		}
	}

	.table-title {

		p {
			margin-bottom: 8px;
		}
	}

	.table-title_crypto {

		p {
			margin: 0;
		}
	}

	.table .table-body .tr .td .coin__icon___text {

		p {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
		}

		span {
			color: var(--Grayscale-Gray-20);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.flex-end {

		@media only screen and (min-width: 1199px) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
		}
	}

	.coin__icon {
		width: 40px;
		height: 40px;
	}

	//crypto
	.crypto-flex {

		.coin {

		}
	}

	.fiatRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.coin__text {
		font-family: var(--font-sans);
		color: var(--Grayscale-White);
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 24px !important; /* 150% */
		letter-spacing: 0;
		text-align: left;
	}

	.coin__text-more {
		color: var(--Grayscale-Gray-20);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 133.333% */
	}

	.cryptoRow {

		.flex-end {

			@media only screen and (min-width: 1199px) {
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				text-align: right;
			}
		}

		.td--right {

			@media only screen and (min-width: 1199px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.td {
			margin: 0;
			height: 100%;

			.crypto-flex {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 12px;

				.coin {
				}
			}
		}
	}

	.table-wrapper {
		overflow: visible;
	}

	.active-trade {
		display: flex;
		justify-content: center;

		@media screen and (max-width: 1199.98px) {
			display: none;
		}
	}

	.active-trade_filters {
		background: #fafafa;
		border: none;
		width: 200px;

		.active-trade_filters--currency .select {
			width: 200px;
			padding: 0;
			background: none;
		}

		.active-trade_filters, .select, .select__current {
			background: none;
		}
	}
	.td--fiat--right {
		margin-right: 40px;
	}
	.user-management-balance, .active-trade_filters {
		background: none;
	}

}
.button--send-pool-wallet {
	display: flex;
	align-items: center;
	font-family: var(--font-sans);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	text-align: left;
	color: var(--Grayscale-Gray-20);

	&:hover {
		color: var(--Blue-Blue-Light);

		svg path[stroke="#777E90"][d="M1.5 1L8.5 8L1.5 15"] {
			stroke: var(--Blue-Blue-Light);
		}

		.button--send-pool-wallet_arrow {
			border-color: var(--Blue-Blue-Light);
		}
	}

	svg path[stroke="#777E90"][d="M1.5 1L8.5 8L1.5 15"] {
		stroke: var(--Grayscale-Gray-20);
		transition: var(--transition-base);
	}
}
.button--send-pool-wallet_arrow {
	margin-left: 8px;
	min-width: 32px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	border: 1px solid var(--Grayscale-Gray-10);
	background: transparent;
	box-shadow: none;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	background-position: center;
	transition: var(--transition-base);
}
