.footer-nav {
	ul {
		display: flex;
		align-items: center;
		gap: 24px;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			gap: 8px;
		}

		> li > a,
		> li > button {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
