.tabs-account {
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	overflow-x: scroll;
	scrollbar-color: transparent transparent;
	scrollbar-width: none;
	user-select: none;
	//background: var(--Grayscale-Gray-80);

	&::-webkit-scrollbar {
		height: 1px;
	}

	&__wrap {
		position: relative;

		&:after {
			content: '';
			display: block;
			background: transparent url(../../../img/tabs-account/shadow.png) 0 0 no-repeat;
			background-size: cover;
			height: 100%;
			width: 40px;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	a {
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
		border-bottom: 2px solid transparent;
		background: var(--Grayscale-Gray-80);
		padding: 17px 17px;
		min-height: 56px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		user-select: none;
		-webkit-user-drag: none;

		&:hover,
		&.active {
			border-color: var(--Blue-Blue-Brand);
			background: var(--Grayscale-Gray-70);
			color: var(--additional-white);
			font-weight: 700;
		}
	}
	&--create-user {
		display: grid;
		grid-template-columns: repeat(7, auto);
	}
}

