.admin-panel {
	.title-block-wrap {
		margin-bottom: 8px !important;
	}

	.title-block {
		.title {
			color: var(--Grayscale-White);
			font-size: 16px !important;
			font-style: normal;
			font-weight: 600;
			line-height: 24px !important; /* 150% */
		}
	}

	.tabs-buttons {
		padding: 0;
		display: inline-flex;
		width: 100%;
		gap: 0;
		border: none;
		border-radius: 8px;
		overflow: hidden;

		a,
		button {
			font-weight: 700;
			font-size: 14px;
			line-height: 143%;
			text-align: center;
			color: var(--Grayscale-White);
			display: inline-flex;
			position: relative;
			align-items: center;
			justify-content: center;
			height: 56px;
			padding: 18px 16px;
			width: 100%;
			border-radius: 0;
			background: var(--Grayscale-Gray-80);

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 2px;
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: var(--Blue-Blue-Brand);
				opacity: 0;
				transition: var(--transition-base);
			}

			&:hover {
				color: var(--Grayscale-Gray-10);
			}

			&.active {
				color: var(--Grayscale-Gray-10);
				font-family: var(--font-sans);
				background: var(--Grayscale-Gray-80);

				@media only screen and (min-width: 1080px) {
					border-color: var(--Blue-Blue-Brand);
				}

				&:before {
					opacity: 1;
				}
			}
		}
	}

	.balance-info,
	.admin-balance-block {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 0;
		border: none;
		border-radius: 6px;
		background: var(--Grayscale-Gray-90);
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.24);
		overflow: hidden;
	}

	.title-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		border-radius: 0 0 6px 6px;
		background: #1d1f21;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

		@media only screen and (max-width: 575.98px) {
			flex-direction: column;
			gap: 8px;
			align-items: flex-start;
		}
	}

	.title-info_left {
		font-family: var(--font-sans);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
	}

	.title-info_right {
		.info {
			font-family: var(--font-sans);
			color: var(--Grayscale-Gray-30);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 150% */
		}

		.results {
			font-family: var(--font-sans);
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
	}

	.title-block {
		display: grid;
		flex-flow: row nowrap;
		grid-template-columns: 2fr 1fr;
		padding: 0 24px 20px 24px;

		@media only screen and (max-width: 991.98px) {
			display: flex;
			flex-direction: column;
			padding: 0 16px 16px 16px;
		}

		&--is-loading {
			display: flex;
			justify-content: center;
		}
	}

	.balance-info-date {
		max-width: 100%;
		width: 100%;
		pointer-events: none;
	}

	.balance-info-list {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__items {
			cursor: auto;
			width: 100%;
			height: auto;
			padding: 0;
			border-radius: 0;
			border: none;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			align-items: center;

			@media only screen and (max-width: 575.98px) {
				grid-template-columns: 1fr;
				gap: 8px;
			}

			.box-info-coin {
				display: block;
				height: auto;

				p {
					font-family: var(--font-sans);
					color: var(--Grayscale-White, #fff);
					text-align: left;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
				}
			}

			.box-info {
				display: flex;
				align-items: center;
				height: auto;
			}

			.box-info_img {
				img {
					width: 24px;
					min-width: 24px;
					height: 24px;
				}
			}

			.box-info_name {
				margin-left: 8px;
				display: flex;
				gap: 4px;

				span.box-info_name__code {
					color: var(--Grayscale-White);
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
				}

				span.box-info_name__name {
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}
	}

	.recharts-wrapper {
		right: 0;
		top: 0;
	}

	.balance-info-schedule {
		display: flex;
		justify-content: center;
	}
}

