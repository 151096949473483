.detailed-view {
	display: flex;
	flex-direction: column;
	//gap: 12px;

	&__title {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	&__text-wrap {
		display: flex;
		gap: 6px;
		align-items: baseline;
	}

	&__text {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		overflow-wrap: anywhere;
	}

	&__more {
		color: var(--text-secondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 2px;
	}
	.input-country-item {
		height: 44px;
		border-radius: 10px;
		background: var(--additional-white);
		border: 1px solid var(--additional-white);
		padding: 8px 16px;
	}
	.input-file {
		margin-bottom: 8px;
	}
}

.detailed-view-form {
	margin-top: 8px;
	form {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	&__links {
		// display: flex;
		// gap: 16px;
		// align-items: center;

		position: relative;
		span {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2224 6.90884C18.5348 6.59642 18.5348 6.08989 18.2224 5.77747C17.9099 5.46505 17.4034 5.46505 17.091 5.77747L11.9999 10.8685L6.90891 5.77749C6.59649 5.46507 6.08996 5.46507 5.77754 5.77749C5.46512 6.08991 5.46512 6.59644 5.77754 6.90886L10.8686 11.9999L5.77728 17.0912C5.46486 17.4036 5.46486 17.9101 5.77728 18.2225C6.0897 18.535 6.59623 18.535 6.90865 18.2225L11.9999 13.1313L17.0912 18.2226C17.4037 18.535 17.9102 18.535 18.2226 18.2226C18.535 17.9101 18.535 17.4036 18.2226 17.0912L13.1313 11.9999L18.2224 6.90884Z' fill='%23626A85'/%3E%3C/svg%3E%0A");
			width: 24px;
			min-width: 24px;
			height: 24px;
			//min-height: 16px;
			//transform: translate(0, 0);
			//top: 4px;
			//right: 20%;
			//position: absolute;
			cursor: pointer;
			vertical-align: middle;
			margin-left: 4px;
			svg {
				display: none;
			}
		}
		a {
			color: var(--Blue-Blue-Brand);
		}
	}
	.select.disabled {
		.coin .coin__text {
			color: var(--Grayscale-White);
		}
	}
}

.wallet-operations-header-wrap {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

