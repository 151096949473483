.main-content {
	width: 100%;
	background: var(--Grayscale-Gray-100);
	padding: 56px 40px 40px 40px;
	transition: width 0.3s ease;
	color: var(--Grayscale-White);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media only screen and (max-width: 1179.98px) {
		padding-top: 16px;
	}

	&--flex {
		display: flex;
		flex-direction: column;
	}
	.error-wrapper {
		margin-top: 16px;
	}
}

