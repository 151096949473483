.table-panel {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 0;

	@media only screen and (max-width: 1023.98px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	&.table-panel--full-4-2 {
		display: grid;
		grid-template-columns: 1.74fr 1fr;
		gap: 24px;

		@media only screen and (max-width: 991.98px) {
			grid-template-columns: 1fr;
			gap: 8px;
		}

		.currencyButton {
			display: grid;
			grid-template-columns: 1fr 1fr;

			@media only screen and (max-width: 767.98px) {
				grid-template-columns: 1fr;
			}

			button, a {
				width: 100%;
			}
		}
	}
}
