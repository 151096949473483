.account-details {
	overflow: hidden;
	.input-item--user-data {
		width: 100%;
		height: 48px;
	}
	/*.tabs-account {
		margin: 24px;
		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 30px;
			padding: 0px 12px;
			border-radius: 30px;
			border: 1px solid;
			color: rgba(119, 126, 144, 1);
			margin-right: 8px;
			margin-bottom: 10px;

			font-family: Euclid Circular A !important;
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 22px !important;
			letter-spacing: 0px !important;
			text-align: center !important;

		}

		a.active {
			background: rgba(33, 44, 99, 1);
			color: white;
		}
	}*/
}
.user-management-wrap {
}
.account {
	.error-red {
		position: relative;
		bottom: 12px;
	}
	&-body {
		margin: 0;
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		padding: 16px 24px;
	}

	&-item {
		margin-bottom: 8px;
		.website {
			position: relative;
			svg {
				position: absolute;
				right: 18px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&__title {
			p {
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 150% */
				letter-spacing: 0;
				text-align: left;
				margin-bottom: 16px;
				margin-top: 0;
			}
		}
		&__actions {
			margin-bottom: 10px;
		}
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0 20px;

		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
			gap: 0;
		}

		&-item {
			width: 100%;

			input {
				width: 100%;
			}

			input:disabled {
				//cursor: not-allowed;
				cursor: default;
				color: var(--Grayscale-Gray-20);
			}

			&__info.is-disabled {
				.react-tel-input-wrapper .form-control {
					color: var(--Grayscale-Gray-20);
				}
			}
		}
	}
	.account-list-item__name {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		letter-spacing: 0;
		text-align: left;
	}
	.account-item__verification___status {
		span {
		}
	}
	.account-item__verification {
		display: flex;
		justify-content: space-between;

		button {
			display: flex;
			align-items: center;
			gap: 8px;
			font-family: var(--font-sans);
			color: var(--Blue-Blue-Light);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
			letter-spacing: 0;
			text-align: left;
			position: relative;

			svg {
				transition: var(--transition-base);
			}
		}

		.btn-details-up {
			svg {
				transform: rotate(180deg);
			}
		}
	}
	.account-list-details {
		margin-top: 24px;
		margin-bottom: 0;
		border-radius: 6px;
		background: var(--Grayscale-Gray-70);
		padding: 16px;

		.verification-attachment-download {
		}
	}
	.account-list-item {
		.account-list-item__name {
			margin-bottom: 0;

			p {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;

				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
	}
	.account-list-item__name {
		p {
			font-family: var(--font-sans);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0;
			text-align: left;
		}
	}

	.account-checkbox {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 16px;
		margin-top: 8px;

		span,
		p {
			color: var(--Grayscale-Gray-20);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
		}
		p {
			min-width: 100px;
		}
		.disabled {
			cursor: not-allowed;
		}
	}

	.select-value,
	.select--selected-value {
	}

	.account-list-item__info {
		.disabled {
		}
	}

	.select-counter {
		.select {
		}
	}
}

