.item-select {
	.select {
		background: var(--Grayscale-Gray-100);

		.selectInput {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
			font-family: var(--font-sans);
			letter-spacing: 0;
			text-align: left;

		}
	}
	.disabled-color {
		background: var(--Grayscale-Gray-100);

		.selectInput {
			color: var(--Grayscale-Gray-20);
		}
	}
}
