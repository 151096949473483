.totalBalances {
	p {
		color: var(--Grayscale-Gray-20);
		font-family: var(--font-sans);
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 160%; /* 22.4px */
	}

	span {
		color: var(--Grayscale-White);
		font-family: var(--font-sans);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
	}
}

