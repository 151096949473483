.login {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	border: none;
	border-radius: 6px;
	background: var(--Grayscale-Gray-80);
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
	padding: 24px;
	max-width: 480px;
	margin: 0 15px;

	@media only screen and (min-width: 768px) {
	}

	@media only screen and (min-width: 1024px) {
	}
	&__2fa {
		max-width: fit-content;
	}
}

.login-recaptcha {
	margin: 16px 0 30px 0;
}

