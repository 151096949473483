.pagination-block {
	padding: 0;
	margin: 0 24px 12px 24px;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	position: relative;
	justify-content: flex-start;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: center;
		gap: 12px;
	}

	&--full {

		.pagination {

			@media only screen and (min-width: 768px) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.select--per-page-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		gap: 16px;
		width: auto;
		height: auto;
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */

		.select .select__drop {
			display: block;
			bottom: calc(100% + 5px) !important;
			top: auto !important;
		}
	}

	.dropdown_wrapper {
		gap: 16px;
		width: auto;
		height: auto;

		.select .select__drop {
			display: block;
			padding: 2px;
		}
	}

	.select,
	.select_pagination {
		width: auto;
		height: 32px;
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-100);
		padding: 0;
		display: block;

		button {
			height: 100%;
			width: 100%;
			padding: 0 8px;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			display: flex;
			align-items: center;
			gap: 2px;

			.select__current-arrow {
				margin-left: 0;
			}
		}
	}

	.select_drop--pagination {
		bottom: calc(100% + 5px) !important;
		top: auto !important;
	}

	.dropdown-title {
		letter-spacing: 0;
		text-align: left;
		font-family: var(--font-sans);
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}

	.select__drop,
	.select_drop--pagination {
		padding: 2px;

		ul {
			li {
				button {
					padding: 5px 10px;
					border-radius: 4px;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400 !important;
					line-height: 160%;
					background: transparent;

					&:hover,
					&.active {
						background: var(--Grayscale-Gray-80);
						color: var(--Grayscale-Gray-10);
					}
				}
			}
		}
	}
}

