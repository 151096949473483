.beneficiaries {

	.tabs-buttons {
		margin-top: 16px;
		margin-bottom: 16px;
		width: 100%;

		a, button {
			width: 50%;
		}
	}

	.table-body {

		span {
			margin-left: 5px;

			@media only screen and (max-width: 1199px) {
				margin-left: 0;
			}
		}
	}

	.input-item.input-item--type-td {
		max-width: 300px;
	}

	.table--type2 .tr .td:first-child  {
		height: 100%;
		display: flex;
		align-items: center;

		@media only screen and (max-width: 1199px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.table--type2 .tr .td {
		height: 100%;
		display: flex;
		align-items: center;

		@media only screen and (max-width: 1199px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.td-colum {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: center;
	}

	.td--right {
		display: flex;
		justify-content: right;
	}
}
