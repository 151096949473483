.pagination {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	margin: 0;

	&__arrow {
		position: relative;

		a:hover {
			&:before {
				background-color: var(--Grayscale-Gray-100);
			}
		}

		&.disabled {

			a {
				cursor: default;
				background: var(--Grayscale-Gray-80);

				&:hover {
					background: var(--Grayscale-Gray-80) !important;

					&:before {
						background-color: var(--Grayscale-Gray-20) !important;
					}
				}
			}
		}

		&--prev {

			a:before {
				content: '';
				display: block;
				width: 16px;
				height: 16px;
				mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M5.42758 8.3199C5.21424 8.1599 5.21424 7.8399 5.42758 7.6799L8.36091 5.4799C8.62461 5.28213 9.00091 5.47028 9.00091 5.7999L9.00091 10.1999C9.00091 10.5295 8.62461 10.7177 8.36091 10.5199L5.42758 8.3199Z' fill='%23626A85'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 16px 16px;
				background-color: var(--Grayscale-Gray-20);
				position: absolute;
				top: calc(50% - 8px);
				left: calc(50% - 8px);
			}
		}

		&--next {

			a:before {
				content: '';
				display: block;
				width: 16px;
				height: 16px;
				mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M10.5733 7.6799C10.7867 7.8399 10.7867 8.1599 10.5733 8.3199L7.64 10.5199C7.37631 10.7177 7 10.5295 7 10.1999L7 5.7999C7 5.47028 7.37631 5.28213 7.64 5.4799L10.5733 7.6799Z' fill='%23626A85'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 16px 16px;
				background-color: var(--Grayscale-Gray-20);
				position: absolute;
				top: calc(50% - 8px);
				left: calc(50% - 8px);
			}
		}

		a:before {
			transition: var(--transition-base);
		}
	}

	> li {
		padding: 0;

		&.active > a:not(.disabled),
		&.active > button:not(.disabled) {
			background: var(--Grayscale-Gray-30);
			color: var(--Grayscale-Gray-100);
		}

		&.arrow {
			background: var(--gray300);
			position: relative;

			a:before {
				transition: var(--transition-base);
			}

			&:hover {
				a:before {
					background-color: #fff;
				}
			}

			&.disabled {
				a:before {
					background-color: #e4e4e4;
				}
			}

			&--prev {
				margin-right: 16px;

				svg {
					display: none;
				}

				a:before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.668 14.1693L7.85486 10.3562C7.65959 10.1609 7.65959 9.84431 7.85486 9.64905L11.668 5.83594' stroke='%23E4E4E4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 20px 20px;
					background-color: #212c63;
					position: absolute;
					top: calc(50% - 10px);
					left: calc(50% - 10px);
				}
			}

			&--next {
				margin-left: 16px;

				svg {
					display: none;
				}

				a:before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.33203 5.83073L12.1451 9.64384C12.3404 9.8391 12.3404 10.1557 12.1451 10.3509L8.33203 14.1641' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 20px 20px;
					background-color: #212c63;
					position: absolute;
					top: calc(50% - 10px);
					left: calc(50% - 10px);
				}
			}
		}

		> a,
		> button {
			border-radius: 6px;
			background: var(--Grayscale-Gray-70);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			min-width: 24px;
			height: 24px;
			margin: 0;
			padding: 0;
			border: 0;
			color: var(--Grayscale-Gray-20);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
			transition: var(--transition-base);

			&:not(.disabled):hover,
			&:not(.disabled):hover {
				background: var(--Grayscale-Gray-30);
				color: var(--Grayscale-Gray-100);
			}

			svg {
				display: none;
			}
		}
	}
}

