.settings-2fa {
	&.settings-2fa--reg-setup {
		.settings-2fa__title {
			font-weight: 500;
		}

		.settings-2fa__title-number {
			background: var(--Grayscale-Gray-30);
		}

		.settings-2fa__text {
			margin-bottom: 20px;
			color: var(--Grayscale-Gray-20);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input__name {
			font-weight: 400;
		}

		.input__notification {
			color: var(--Special-Red);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: block;
			padding: 0;
			background: transparent;
			margin-top: 5px;

			&:before {
				display: none;
			}

			&--type3 {
				margin-top: 15px;
				display: flex;
				align-items: center;

				color: var(--Grayscale-White);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border-radius: 6px;
				background: var(--Grayscale-Gray-30);
				padding: 8px 0 8px 16px;

				&:before {
					display: block;
					content: '';

					background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9998 1.6998C5.86356 1.6998 1.6998 5.86356 1.6998 10.9998C1.6998 16.1361 5.86356 20.2998 10.9998 20.2998C16.1361 20.2998 20.2998 16.1361 20.2998 10.9998C20.2998 5.86356 16.1361 1.6998 10.9998 1.6998ZM0.299805 10.9998C0.299805 5.09036 5.09036 0.299805 10.9998 0.299805C16.9093 0.299805 21.6998 5.09036 21.6998 10.9998C21.6998 16.9093 16.9093 21.6998 10.9998 21.6998C5.09036 21.6998 0.299805 16.9093 0.299805 10.9998ZM11.0002 4.19983C11.442 4.19983 11.8002 4.558 11.8002 4.99983V13.9998C11.8002 14.4417 11.442 14.7998 11.0002 14.7998C10.5584 14.7998 10.2002 14.4417 10.2002 13.9998V4.99983C10.2002 4.558 10.5584 4.19983 11.0002 4.19983ZM11.8002 16.9998C11.8002 16.558 11.442 16.1998 11.0002 16.1998C10.5584 16.1998 10.2002 16.558 10.2002 16.9998V17.4998C10.2002 17.9417 10.5584 18.2998 11.0002 18.2998C11.442 18.2998 11.8002 17.9417 11.8002 17.4998V16.9998Z' fill='%238D99C5' /%3E%3C/svg%3E");
					width: 24px;
					min-width: 24px;
					height: 24px;
					min-height: 24px;
				}
			}
		}
	}

	&__title {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: var(--Grayscale-White);
		margin-bottom: 12px;
	}

	&__title-number {
		width: 26px;
		height: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		background: var(--Grayscale-Gray-30);
		border: 0;
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		text-align: center;
		color: var(--Grayscale-Gray-100);
	}

	&__text {
		margin-bottom: 20px;
		color: var(--Grayscale-Gray-20);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	&__divider {
		border-top: 1px solid var(--Grayscale-Gray-30);
		width: 100%;
		height: 1px;
		margin: 24px 0;
	}

	.input {
		margin-bottom: 0;
	}

	.input__name {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 0;
	}

	.input-item {
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-100);
		height: 48px;
		padding: 0 16px;
		font-size: 16px !important;
		font-weight: 400;
		color: var(--Grayscale-White);
		font-family: var(--font-sans);

		&::placeholder {
			font-size: 16px !important;
			font-weight: 400;
			color: var(--Grayscale-Gray-20);
			opacity: 1;
			font-family: var(--font-sans);
		}
	}

	.input__icon {
		position: absolute;
		top: 50%;
		right: 16px;
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		transform: translateY(-50%);

		&--eye {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.35173 5.59572C7.92325 4.30899 9.85905 3.25 12 3.25C14.141 3.25 16.0768 4.30899 17.6483 5.59572C19.2284 6.88946 20.5204 8.47865 21.3886 9.68801C21.4126 9.7215 21.4365 9.75468 21.4602 9.78759C21.9829 10.5138 22.4131 11.1117 22.4131 12C22.4131 12.8883 21.9829 13.4862 21.4602 14.2124C21.4365 14.2453 21.4126 14.2785 21.3886 14.312C20.5204 15.5214 19.2284 17.1105 17.6483 18.4043C16.0768 19.691 14.141 20.75 12 20.75C9.85905 20.75 7.92325 19.691 6.35173 18.4043C4.77164 17.1105 3.47962 15.5214 2.61142 14.312C2.58738 14.2785 2.5635 14.2453 2.53981 14.2124C2.01715 13.4862 1.58691 12.8883 1.58691 12C1.58691 11.1117 2.01715 10.5138 2.53981 9.78759C2.5635 9.75467 2.58738 9.7215 2.61142 9.68801C3.47962 8.47865 4.77164 6.88946 6.35173 5.59572ZM7.302 6.75631C5.86348 7.93414 4.6592 9.40765 3.82993 10.5628C3.20597 11.4319 3.08691 11.6415 3.08691 12C3.08691 12.3585 3.20597 12.5681 3.82993 13.4372C4.6592 14.5923 5.86349 16.0659 7.302 17.2437C8.7491 18.4285 10.3542 19.25 12 19.25C13.6458 19.25 15.2509 18.4285 16.698 17.2437C18.1365 16.0659 19.3408 14.5923 20.1701 13.4372C20.794 12.5681 20.9131 12.3585 20.9131 12C20.9131 11.6415 20.794 11.4319 20.1701 10.5628C19.3408 9.40765 18.1365 7.93414 16.698 6.75631C15.2509 5.57146 13.6458 4.75 12 4.75C10.3542 4.75 8.7491 5.57146 7.302 6.75631ZM8.25001 12C8.25001 9.92893 9.92894 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92894 15.75 8.25001 14.0711 8.25001 12ZM12 9.75C10.7574 9.75 9.75001 10.7574 9.75001 12C9.75001 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z' fill='%23626A85' /%3E%3C/svg%3E");
		}
	}

	.input__notification {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 2px;
		color: var(--Grayscale-White);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border-radius: 4px;
		background: var(--Grayscale-Gray-30);

		padding: 8px 0 8px 16px;

		&:before {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.9998 1.6998C5.86356 1.6998 1.6998 5.86356 1.6998 10.9998C1.6998 16.1361 5.86356 20.2998 10.9998 20.2998C16.1361 20.2998 20.2998 16.1361 20.2998 10.9998C20.2998 5.86356 16.1361 1.6998 10.9998 1.6998ZM0.299805 10.9998C0.299805 5.09036 5.09036 0.299805 10.9998 0.299805C16.9093 0.299805 21.6998 5.09036 21.6998 10.9998C21.6998 16.9093 16.9093 21.6998 10.9998 21.6998C5.09036 21.6998 0.299805 16.9093 0.299805 10.9998ZM11.0002 4.19983C11.442 4.19983 11.8002 4.558 11.8002 4.99983V13.9998C11.8002 14.4417 11.442 14.7998 11.0002 14.7998C10.5584 14.7998 10.2002 14.4417 10.2002 13.9998V4.99983C10.2002 4.558 10.5584 4.19983 11.0002 4.19983ZM11.8002 16.9998C11.8002 16.558 11.442 16.1998 11.0002 16.1998C10.5584 16.1998 10.2002 16.558 10.2002 16.9998V17.4998C10.2002 17.9417 10.5584 18.2998 11.0002 18.2998C11.442 18.2998 11.8002 17.9417 11.8002 17.4998V16.9998Z' fill='%238D99C5' /%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center;
			width: 24px;
			min-width: 24px;
			height: 24px;
			min-height: 24px;
		}
	}
}

