.add-account-search-list {
	margin-bottom: 24px;
	max-height: 168px;
	padding: 0;
	background: var(--Grayscale-Gray-100);
	border-radius: 4px;
	border: 1px solid var(--Grayscale-Gray-100);
	scrollbar-color: var(--Grayscale-Gray-20) transparent;
	scrollbar-width: thin;

	.add-account-search-item {
		width: 100%;
		height: auto;
		padding: 12px 16px;
		display: flex;
		align-items: center;
		gap: 10px;
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 5600;
		line-height: normal;

		span {
			color: var(--Grayscale-Gray-20);
			font-weight: 400;
		}
		&:hover {
			color: var(--Grayscale-Gray-10);
			background: var(--Grayscale-Gray-80);
			span {
				color: var(--Grayscale-Gray-10);
			}
		}
	}
	.text--center {
		color: var(--Grayscale-Gray-20);
		margin: 12px 0;
	}
}

