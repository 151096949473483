*[class*='--mt-0'] {
	margin-top: 0 !important;
}

*[class*='--mb-0'] {
	margin-bottom: 0 !important;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

*[class*="--gap-4"] {
	gap: 4px !important;
}

*[class*="--gap-40"] {
	gap: 40px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-24 {
	margin-bottom: 24px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-32 {
	margin-bottom: 32px !important;
}

.mb-35 {
	margin-bottom: 35px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-16 {
	margin-top: 16px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-35 {
	margin-top: 35px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.ml-4 {
	margin-left: 4px !important;
}

.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.d-flex {
	display: flex !important;
}

.d-inline {
	display: inline !important;
}

.align-items-center {
	align-items: center !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-medium {
	font-weight: 500 !important;
}

.max-w-185px {
	max-width: 185px;
}

.mx-auto {
	margin: 0 auto;
}

.text-2xl-right {

	@media only screen and (min-width: 1200px) {
		text-align: right;
	}
}

.text-md-right {

	@media only screen and (min-width: 768px) {
		text-align: right;
	}
}

.text-sm-center {

	@media only screen and (min-width: 576px) {
		text-align: center;
	}
}

.text-sm-right {

	@media only screen and (min-width: 576px) {
		text-align: right;
	}
}

.text-sm-left {

	@media only screen and (min-width: 576px) {
		text-align: center;
	}
}

.gap-16 {
	gap: 16px !important;
}

.green-bg {
	padding: 4px 6px;
	display: inline-block;
	color: var(--Special-Green);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	background: transparent;
	border-radius: 20px;
	border: 0.8px solid var(--Special-Green);
}

.red-bg {
	padding: 4px 6px;
	display: inline-block;
	color: var(--Special-Red);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	background: transparent;
	border-radius: 20px;
	border: 0.8px solid var(--Special-Red);
}

.yellow-bg {
	padding: 4px 6px;
	display: inline-block;
	color: var(--Special-Yellow);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	background: transparent;
	border-radius: 20px;
	border: 0.8px solid var(--Special-Yellow);
}
