.custom-checkbox {
	display: flex;
	flex-direction: column;

	&--disabled {

		label {
			cursor: default;
		}
	}

	input {
		width: 20px;
		height: 20px;
		padding: 0;
		margin: 0;
		display: none;

		&:checked ~ label .custom-checkbox__checkbox {
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='4' fill='%232E2DED'/%3E%3Cpath d='M8.33266 12.9882L5.58849 10.2441L4.41016 11.4224L8.33266 15.3449L16.4218 7.25573L15.2435 6.07739L8.33266 12.9882Z' fill='white'/%3E%3C/svg%3E%0A");
		}
	}

	label {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__checkbox {
		width: 20px;
		min-width: 20px;
		height: 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='4' fill='%230D0F0E'/%3E%3C/svg%3E%0A");
	}

	&__label {
		color: #FFF;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
}
