.buttonsChains {
	display: flex;
	flex-direction: column;
	gap: 8px;

	button {
		justify-content: center !important;
		align-items: center !important;
		width: 250px;
		margin-bottom: 0;
	}
}
