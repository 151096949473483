.copy-button {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 2px;

	&--long {
		.copy-button__text {
			width: 120px;
			white-space: break-spaces;
		}
	}

	&--long-type-2 {
		.copy-button__text {
			width: 120px;
			white-space: break-spaces;
		}
	}

	&__text {
		width: 90px;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
	}

	button {
		display: flex;
		align-items: center;
		gap: 4px;

		span {
			color: var(--Blue-Blue-Light);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}
}

