.input {
	margin-bottom: 16px;

	&--floating {
		position: relative;
	}

	&__notification {
		margin-top: 5px;
		color: var(--Special-Red);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		&--type5 {
			padding: 0 16px;
			margin-top: 0;

			p {
				color: var(--greyscale-grey-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: space-between;

				> span {
					display: flex;
					align-items: center;
					gap: 10px;
					color: var(--blue600);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;

					span {
						color: var(--greyscale-grey-500);
					}
				}
			}
		}
	}

	&__name {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		margin-bottom: 0;
	}

	&__name_wallets_popup {
		display: block;
		margin-bottom: 0;
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}

	&__notification--type2 {
		a {
			color: var(--Blue-Blue-Light);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}
}

.totp .input__notification--type2 a {
	font-size: 12px;
	font-weight: 400;
	color: var(--blue400);
}

.input-wrapper {
	&--error {
		border: 1px solid var(--Special-Red);
		border-radius: 4px;
	}
	.input--error .input-item {
		border: 1px solid var(--Special-Red);
	}
}

