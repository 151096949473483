.buttons-flexcontainer {
	font-family: var(--font-sans) !important;
	justify-content: flex-start;
}

.button--size41.active {
	font-family: var(--font-sans) !important;
	color: #0071ab !important;
	font-weight: 600;
	background: #d4edfc !important;
	box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1) !important;
}

.tabs-buttons {
	margin-bottom: 16px;
	padding: 0;
	display: inline-flex;
	gap: 0;
	border: none;
	border-radius: 8px;
	overflow: hidden;

	a,
	button {
		border-radius: 0;
		background: transparent;
		width: 180px;
		height: auto;
		min-height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 16px;
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
		border-bottom: 2px solid transparent;
		background: var(--Grayscale-Gray-80);

		&:hover,
		&.active {
			color: var(--Grayscale-Gray-10);
			font-weight: 700;
			background: var(--Grayscale-Gray-80);
			box-shadow: none;
			border-color: var(--Blue-Blue-Brand);
		}
	}
	&--pending,
	&--referral {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		a {
			width: 100%;
		}
	}

	&--liquidity {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		button {
			width: 100%;
		}
	}
	&--wallets {
		button {
			border-radius: 8px;
		}
	}
	&--balances {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 80%;
		margin-bottom: 0;
		button {
			width: 100%;
		}
	}
	&--limits,
	&--default-fees {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		button {
			width: 100%;
		}
	}
}

.tabs-buttons-nav {
	display: grid;
	grid-template-columns: repeat(6, 1fr) 1.1fr;
	border: 1px solid var(--greyscale-grey-100);
	border-radius: 14px;
	width: 100%;
	margin-bottom: 24px;

	@media screen and (max-width: 1380px) {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1px;
	}
	@media screen and (max-width: 1080px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (max-width: 620px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

.account-tabs {
	border: 1px solid var(--greyscale-grey-100);
	border-radius: 14px;
	a {
		padding: 0 12px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		border-right: none;
		color: var(--greyscale-grey-500);
		background: var(--additional-white);
		position: relative;

		&:first-child,
		&:last-child {
			border-radius: 14px;
		}
		&:not(:last-child) {
			&::before {
				content: '';
				height: 14px;
				display: block;
				width: 1px;
				position: absolute;
				right: 0px;
				top: 18px;
				background-color: var(--greyscale-grey-100);
			}
		}

		&.active {
			color: var(--greyscale-grey-800);
			font-weight: 600;
			background: #fff;
			position: relative;
			&::after {
				content: '';
				display: block;
				height: 2px;
				background-color: var(--primary-blue-400); /* Колір підкреслення */
				position: absolute;
				bottom: 8px;
				left: 0;
				right: 0;
				margin: auto;
				width: 80%;
			}
		}
	}
}

.tabs-buttons-flex {
	max-width: fit-content;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 24px;

	a {
		min-width: 160px;
	}
}

.tabs-buttons--transfer-history {
	max-width: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
	margin: 16px 0;
	gap: 0;
	padding: 0;
	border: none;
	border-radius: 8px;
	overflow: hidden;

	a,
	button {
		width: 50%;

		@media only screen and (max-width: 767.98px) {
			width: 100%;
		}
	}
}

.tabs-buttons-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	@media screen and (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}

	a {
		width: 100%;
	}
}

.tabs-buttons-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

