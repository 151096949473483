.verification-attachment {
	max-width: 100%;
	margin-bottom: 16px;
	display: flex;
	height: auto;
	gap: 10px;

	&__item {
		border-radius: 6px;
		border: 0.5px solid var(--Greyscale-Grey-100);
		overflow: hidden;
		width: 115px;
		height: 80px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
