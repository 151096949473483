.editor {
	&__container {
		border-radius: 8px;
		border: 1px solid var(--Grayscale-Gray-100);
		background: var(--Grayscale-Gray-100);
		height: 400px;
		padding: 12px 16px;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;

		span {
			display: inline;
		}
		&--error {
			border-color: var(--Special-Red);
		}
	}
	&__wrapper {
		cursor: text;
		overflow-y: auto;
		height: fit-content;
		max-height: 350px;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;
		.public-DraftEditorPlaceholder-root {
			font-size: 16px;
			font-weight: 400;
			color: var(--Grayscale-Gray-20);
			opacity: 1;
			font-family: var(--font-sans);
		}
	}
	&__toolbar {
		border: none;
		.rdw-option-wrapper {
			border: none;
		}
		&--disabled {
			pointer-events: none;
		}
		.rdw-link-modal {
			height: fit-content;
		}
	}
}

.rdw-editor-main {
	padding: 10px;
}

.rdw-editor-main .public-DraftEditor-content {
	outline: none;
	caret-color: white;
}

.rdw-editor-toolbar {
	background-color: var(--Grayscale-Gray-80);
	border-radius: 4px;
}

.rdw-option-wrapper {
	background-color: var(--Grayscale-Gray-30);
	&:hover {
		box-shadow: 1px 1px 0px var(--Grayscale-Gray-10);
	}
}

.rdw-emoji-modal {
	background-color: var(--Grayscale-Gray-80);
	box-shadow: none;
	border-color: var(--Grayscale-Gray-80);
	scrollbar-color: #b6b6b6 transparent;
	scrollbar-width: thin;
}

.rdw-option-active {
	box-shadow: 1px 1px 0px var(--Blue-Blue-Light);
}

