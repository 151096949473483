.user-management-item {
	padding: 0;
	border: 0;

	&__title {
		&--type3 {
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 150% */
			margin-bottom: 16px;
		}
	}

	.select-counter {
		.select {
			height: 48px;

			border-radius: 4px;
		}
	}

	.website {
		position: relative;

		svg {
			position: absolute;
			right: 18px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

