.footer {
	border-top: 1px solid var(--Grayscale-Gray-80);
	background: var(--Grayscale-Gray-100);
	@media only screen and (min-width: 1179.98px) {
		display: none;
	}

	&__visible {
		display: block;
	}

	.footer-container {
		padding: 16px 48px;
		display: flex;
		gap: 8px;

		@media only screen and (min-width: 768px) {
			align-items: center;
			justify-content: space-between;
		}

		@media only screen and (min-width: 1280px) {
			padding: 14px 48px;
		}

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			align-items: center;
		}
	}

	.logo {
		width: 158px;

		@media only screen and (max-width: 767.98px) {
			margin-bottom: 30px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__support {

		a,
		button {
			color: var(--Grayscale-Gray-30);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			text-decoration: none;

			@media only screen and (max-width: 767.98px) {
				color: var(--Grayscale-Gray-20);
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

