.switch {
	&__toggler {
		position: relative;
		display: block;
		width: 38px;
		height: 22px;
		border: 1px solid var(--Grayscale-Gray-10);
		background-color: var(--Grayscale-Gray-10);
		border-radius: 13px;
		cursor: pointer;
	}

	&__label {
		display: flex;
		align-items: center;
		gap: 12px;
		.disabled {
			.switch__toggler {
				cursor: not-allowed;
			}
		}
	}

	&__text {
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 180%; /* 25.2px */
	}

	&--type2 .switch__toggler {
		background: var(--Grayscale-Gray-10);
		border-color: var(--Grayscale-Gray-10);
	}

	input:checked ~ .switch__toggler {
		border-color: var(--Blue-Blue-Dark);
		background: var(--Blue-Blue-Dark);

		&:before {
			left: 18px;
		}
	}

	.disabled {
		.switch__toggler {
			cursor: not-allowed;
		}
	}
}

