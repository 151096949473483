.wallet-content-tables {
	padding: 0;

	.block--middle {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		gap: 8px;

		@media only screen and (min-width: 768px) {
			flex-direction: row;
			align-items: center;
		}
	}
}

