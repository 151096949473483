.login-section {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px 0 20px 0;
	background: var(--body-bg);
	position: relative;
	flex: 1;
	min-height: 500px;

	.container {
		padding: 0 15px;
		width: 100%;
		display: flex;
		align-items: stretch;
		gap: 15px;
		justify-content: center;

		@media only screen and (min-width: 1024px) {
			padding: 0 40px;
			gap: 40px;
		}
	}

	.input__notification--ta-r {
		margin: 5px 0 0 0;
		text-align: left;
	}

	&__logo {
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}
}

