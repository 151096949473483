.table-walletBalance {
	&--platformBalance-crypto {
		.table-header .td-name {
			@media only screen and (min-width: 1199px) {
				width: auto;
				display: inline-flex;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	&--platformBalance-fiat {
		.table-header .td-name {
			@media only screen and (min-width: 1199px) {
				width: auto;
				display: inline-flex;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	&--depositWallets-crypto {
		.table-header .td-name {
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 1.3fr;
			}
		}
	}

	&--pendingFees-crypto {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 1.3fr;
			}
		}

		.buttonsChains {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 8px;

			button {
				width: 100%;
				margin-bottom: 0;
			}
		}
		.td--m-100 {
			margin-right: 100px;
		}
	}

	&--deposit-wallet-totals-crypto {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 2fr 2.2fr;
			}
		}

		.td__wrap {
			p {
				display: flex;
				flex-direction: column;
				gap: 4px;
				color: var(--Grayscale-White);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */

				span {
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
		.td--right .td__wrap {
			align-items: flex-end;
		}

		.buttonsChains {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 8px;

			button {
				width: 100%;
				min-width: inherit;
				margin-bottom: 0;
			}
		}
	}

	&--deposit-wallet-totals-fiat {
		.td-sorting-arrow-button {
			justify-content: space-between !important;
		}

		.table-header .td-name {
			@media only screen and (min-width: 1199px) {
				width: 210px;
				display: inline-flex;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
		}

		.td__wrap {
			p {
				display: flex;
				flex-direction: column;
				gap: 4px;
				color: var(--Grayscale-White);

				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */

				span {
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
	}

	&--deposit-wallet-user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 0.6fr 1fr 1.2fr 1fr 0.8fr 1fr 1.3fr;
			}
		}

		.buttonsChains {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: 8px;

			button {
				width: 100%;
				min-width: inherit;
				margin-bottom: 0;
			}
		}
	}

	&--deposit-wallet-user-fiat {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 0.8fr 1fr 1fr 1fr 1fr 1fr;
			}
		}
	}

	&--platformBalance-crypto,
	&--platformBalance-fiat,
	&--depositWallets-crypto,
	&--pendingFees-crypto,
	&--deposit-wallet-totals-crypto,
	&--deposit-wallet-totals-fiat,
	&--deposit-wallet-user,
	&--deposit-wallet-user-fiat {
		.table-footer {
			padding: 8px 0;
		}
		.tr .td .td__wrap {
			.td-name {
				font-weight: 700;
				font-size: 14px;
			}
		}

		.table-body .tr {
			padding-top: 2px;
			padding-bottom: 2px;
		}
	}
}

