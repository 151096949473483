.popup-search {
	display: flex;
	align-items: center;
	gap: 8px;

	.select {
		height: 48px;
		border-radius: 4px;
		min-width: 90px;

		&.active {
			.select__current {
				background-color: var(--Grayscale-Gray-100);
				border-color: var(--Blue-Blue-Brand);
			}
		}

		&__current {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			background-color: var(--Grayscale-Gray-100);
			padding: 0 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1 0 auto;
		}

		&__current-text {
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			white-space: nowrap;
		}

		&__current-arrow {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.31916 10.5733C8.15916 10.7867 7.83916 10.7867 7.67916 10.5733L5.47916 7.64C5.28139 7.37631 5.46955 7 5.79916 7L10.1992 7C10.5288 7 10.7169 7.37631 10.5192 7.64L8.31916 10.5733Z' fill='%23626A85'/%3E%3C/svg%3E%0A");
			width: 16px;
			min-width: 16px;
			height: 16px;
			min-height: 16px;
			margin-left: 5px;

			svg {
				display: none;
			}
		}

		.select__drop {
			padding: 2px;

			ul > li > button {
				padding: 5px 10px;
				border-radius: 4px;
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
				background: transparent;

				&:hover {
					background: var(--Grayscale-Gray-80);
					color: var(--Grayscale-Gray-10);
				}
			}
		}
	}

	.input {
		margin-bottom: 0;
		height: 48px;
		width: 100%;
	}

	.input-wrapper {
		height: 100%;
	}

	.input-item {
		height: 100%;
		border-radius: 4px;
		border: 0;
		padding: 12px 26px 12px 8px;
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
	}

	.input-icon {
		position: absolute;
		top: 50%;
		left: auto;
		right: 8px;
		transform: translateY(-50%);
	}
}

