.input.input--success .input-item {
	border: 1px solid var(--Grayscale-Gray-80);
}

.input-item {
	height: 48px;
	border-radius: 4px;
	border: 1px solid var(--Grayscale-Gray-80);
	background: var(--Grayscale-Gray-100);
	padding: 0 16px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	color: var(--Grayscale-White);
	font-family: var(--font-sans);

	&::placeholder {
		color: var(--Grayscale-Gray-20);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
		opacity: 1;
		font-family: var(--font-sans);
	}

	&--floating {
		padding: 0;
		border: 0;
		border-bottom: 1px solid var(--Grayscale-Gray-10);
		border-radius: 0;
		background-color: transparent;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;

		&::placeholder {
			color: transparent;
		}

		&:focus + label,
		&:not(:placeholder-shown) + label {
			top: -6px;
			font-size: 12px;
		}

		+ label {
			color: var(--Grayscale-Gray-10);
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 1;
			position: absolute;
			top: 15px;
			left: 0;
			transition: var(--transition-base);
		}
	}

	&.input-item--type-td {
		width: 100%;
		max-width: 140px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-80);
		background: var(--Grayscale-Gray-100);
		padding: 0 8px;
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		transition: border-color var(--transition-base);

		&::placeholder {
			color: var(--Grayscale-Gray-20);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		&:focus {
			border-color: var(--Blue-Blue-Brand);
		}
	}

	&.input-item--left-icon {
		padding-left: 40px;
	}

	&.input-item--left-icon-bigger {
		padding-left: 50px;
	}

	&.input-item--pr40 {
		padding-right: 40px !important;
	}

	&.input-item--pr50 {
		padding-right: 50px !important;
	}
}
