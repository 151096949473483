.user-management-body {
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 0;

	@media screen and (max-width: 767px) {
		padding: 0;
	}

	&--type2 {
		display: block;

		@media screen and (max-width: 767px) {
			padding: 24px 12px;
		}
	}

	.table-block {
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-left: 0;
			border-left: 0;
			border-right: 0;
			border-radius: 0;
		}
	}
	&--wrap {
		background: var(--Grayscale-Gray-80);
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		border-radius: 6px;
		gap: 24px;
		padding: 16px 24px;
	}
}

.user-management {
	max-width: 100%;
	&__heading {
		color: var(--greyscale-grey-800);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.table {
		.table-body {
			min-height: 200px;

			.tr {
				min-height: 58px;

				.td {
					p {
						color: var(--Grayscale-White);
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 150% */
						padding: 0;
					}
					.td-currency-name {
						font-weight: 700;
						font-size: 14px;
					}

					a {
						color: var(--Blue-Blue-Light);
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 150% */

						&:hover {
							color: var(--Blue-Blue-Brand);
						}
					}
				}
			}
		}
	}

	.table {
		.select--table {
			//padding: 6px 10px 14px 10px;
			display: inline-flex;
			width: auto;
			color: var(--gray600);
			border-radius: 0;
			background: transparent;
			border: none;

			&.active {
				border: none;
				background: transparent;
			}

			button {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 700 !important;
				line-height: 20px; /* 142.857% */
				font-family: var(--font-sans);
				letter-spacing: 0;
				text-align: left;

				&.select__current {
					display: inline-flex;
					width: auto;

					.selected__current_text {
						display: flex;
						align-items: center;
						flex-direction: row;
						gap: 8px;

						img {
							width: 18px;
							min-width: 18px;
							height: 18px;
						}
					}
				}
			}
		}
	}

	.table-buttons {
		button {
			display: flex;
			align-items: center !important;
			justify-content: center !important;
		}

		.unblock {
			border-radius: 6px;
			border: 1px solid var(--additional-green);
			background: var(--transparent-green);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}

		.block {
			border-radius: 6px;
			border: 1px solid var(--blue200);
			background: var(--blue100);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}

		.trash {
			border-radius: 6px;
			border: 1px solid var(--Special-Red);
			background: var(--transparent-red);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}
	}

	.table-pagination {
		.select--per-page-wrapper {
			gap: 16px;
			display: flex;
			align-items: center;
			font-size: 14px;
			color: var(--blue600);

			.select {
				width: 80px;
				height: 32px;
				border-radius: 6px;
				border: 1px solid var(--greyscale-grey-100);
				background: var(--greyscale-grey-10);

				&.active {
					border: 1px solid var(--primary-blue-200);
					background: var(--additional-white);
				}

				button {
					font-weight: 500;
					font-size: 14px;
					color: var(--greyscale-grey-600);
				}

				.select__drop {
					top: unset;
					bottom: calc(100% + 5px);
				}
			}
		}
	}

	.trade-history-wrapper .active-trade_filters {
		margin-top: 20px;
	}

	.transfer-history-wrapper .active-trade_filters {
		justify-content: flex-start;
		gap: 8px;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			justify-content: stretch;
			align-items: stretch;
			padding: 8px 12px;
		}

		.reset_trade-filters {
			margin-left: auto;
		}
	}

	.select-block__name {
		color: var(--Grayscale-Gray-20);
	}

	.error-red span {
		color: var(--Special-Red);
	}
}

.user-management,
.main-content {
	// .react-datepicker {
	// 	padding: 8px;

	// 	&__navigation-icon--previous,
	// 	&__navigation-icon--next {
	// 		background: none;
	// 		left: 0;
	// 	}

	// 	&__navigation--next {
	// 		right: 0;
	// 		top: -5px;
	// 	}

	// 	&__navigation--previous {
	// 		left: 0;
	// 		top: -5px;
	// 	}

	// 	&__selected-range {
	// 		display: flex;
	// 		justify-content: space-around;
	// 		color: var(--greyscale-grey-800);
	// 		font-size: 12px;
	// 		font-weight: 500;
	// 		margin-bottom: 16px;

	// 		p {
	// 			color: var(--greyscale-grey-200);
	// 			font-size: 12px;
	// 			font-style: normal;
	// 			font-weight: 300;
	// 		}

	// 		svg {
	// 			padding: 6px;

	// 			path {
	// 				fill: var(--Blue-Blue-Light);
	// 			}
	// 		}
	// 	}

	// 	&__day-names {
	// 		display: flex;
	// 		justify-content: space-between;
	// 	}

	// 	&__month {
	// 		margin: 14px 0 0 0;
	// 		width: 100%;
	// 	}

	// 	&__day {
	// 		&:hover {
	// 			border-radius: 4px;
	// 			background: var(--blue400) !important;
	// 			color: var(--additional-white);
	// 		}
	// 	}

	// 	&__day--in-range {
	// 		border-radius: 4px !important;
	// 		background: var(--Blue-Blue-Brand) !important;
	// 		color: var(--additional-white) !important;
	// 		font-weight: 500;

	// 		&::before {
	// 			background: none;
	// 		}
	// 	}

	// 	&__day--keyboard-selected {
	// 		color: var(--primary-blue-400);
	// 		border-radius: 4px !important;
	// 		font-weight: 500;
	// 	}

	// 	&__current-month {
	// 		font-size: 14px;
	// 	}

	// 	&__day--range-end {
	// 		background: var(--Blue-Blue-Brand) !important;
	// 		color: var(--additional-white) !important;

	// 		&.react-datepicker__day--in-range {
	// 			border-radius: 4px !important;
	// 		}
	// 	}

	// 	&__day--in-selecting-range {
	// 		background-color: rgba(33, 107, 165, 0.5) !important;
	// 	}
	// }
}

.table--user-management .tr {
	grid-template-columns: 1fr 1.8fr 1.8fr 1.5fr 1.5fr 1.2fr 1fr;

	.select {
		height: auto;
	}
}

.table--user-management .table-body .td {
	p {
		padding: 8px 0;
	}
}

.table--user-management-fee-management-withdraw-crypto,
.table--user-management-fee-management-deposit-crypto,
.table--user-management-fee-management-trade,
.table--user-management-fee-management-spread,
.table--user-management-fee-management-deposit-fiat {
	.table-body .tr .td .td__wrap .td-name {
		font-weight: 700;
		font-size: 14px;
	}
	.table-footer {
		padding: 8px 0;
	}
}
.table--user-management-fee-management-spread {
	.table-body .tr .td .td__coin-pair .td__coin-pair-item .td-name {
		font-weight: 700;
		font-size: 14px;
	}
}
.user-management-wrap {
	margin-top: 28px;
	border: none;
	border-radius: 8px;
	text-align: left;

	&--create-account {
		margin: 0;
		.user-management__heading {
			color: var(--Grayscale-White);
			font-size: 16px;
			margin-bottom: 12px;
		}
	}
}

