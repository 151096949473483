.burger {
	margin: 0;
	padding: 0;
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='31' height='31' rx='3.5' stroke='%238D99C5'/%3E%3Crect x='10' y='15.5' width='12' height='1' rx='0.5' fill='%23626A85'/%3E%3Crect x='10' y='12' width='12' height='1' rx='0.5' fill='%23626A85'/%3E%3Crect x='10' y='19' width='12' height='1' rx='0.5' fill='%23626A85'/%3E%3C/svg%3E%0A");
	background-size: 32px 32px;
	border: none;
	display: none;

	@media only screen and (max-width: 1179.98px) {
		display: block;
	}
}

