.td {
	.green-bg,
	.yellow-bg,
	.red-bg,
	.pending-status,
	.failed-status,
	.completed-status,
	.success-status,
	.admin_pending-status,
	.rejected-status,
	.cancelled-status,
	.broadcasting-status,
	.pending_signing-status {
		padding: 0;
		display: inline-block;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
		color: var(--Grayscale-White);
		background: transparent;
		border-radius: 0;
		border: none;

		@media screen and (max-width: 1199px) {
		}
	}
	.yellow-bg,
	.pending-status,
	.admin_pending-status {
		color: var(--Special-Yellow, #ee9e53);
	}

	.red-bg,
	.failed-status,
	.rejected-status,
	.cancelled-status {
		color: var(--Special-Red, #eb5757);
	}

	.green-bg,
	.completed-status,
	.success-status {
		color: var(--Special-Green);
	}
	.broadcasting-status {
		color: var(--Blue-Blue-Light);
	}
	.pending_signing-status {
		color: var(--Purple-Purple-Light);
	}

	.status {
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 4px 6px;
		min-width: 88px;
		border-radius: 20px;
		border: 0.8px solid var(--greyscale-grey-800);

		display: inline-flex;
		align-items: center;
		justify-content: flex-start;

		&--accepted {
			color: var(--Special-Green);
		}

		&--rejected {
			color: var(--Special-Red);
		}

		&--new {
			color: var(--Grayscale-Gray-10, #8d99c5);
		}
	}
}

