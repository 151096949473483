.verification {
	.drop-file-input {
		position: relative;
		width: 400px;
		height: 200px;
		border: 2px dashed var(--border-color);
		border-radius: 20px;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: var(--input-bg);
	}

	.drop-file-input input {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.drop-file-input:hover,
	.drop-file-input.dragover {
		opacity: 0.6;
	}

	.drop-file-input__label {
		text-align: center;
		color: var(--txt-second-color);
		font-weight: 600;
		padding: 10px;
	}

	.drop-file-input__label img {
		width: 100px;
	}

	.drop-file-preview {
		margin-top: 30px;
	}

	.drop-file-preview p {
		font-weight: 500;
	}

	.drop-file-preview__title {
		margin-bottom: 20px;
	}

	.drop-file-preview__item {
		position: relative;
		display: flex;
		margin-bottom: 10px;
		background-color: #f5f8ff;
		padding: 15px;
		border-radius: 20px;
	}

	.drop-file-preview__item img {
		width: 50px;
		margin-right: 20px;
	}

	.drop-file-preview__item__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.drop-file-preview__item__del {
		background-color: var(--box-bg);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		box-shadow: var(--box-shadow);
		cursor: pointer;
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	.drop-file-preview__item:hover .drop-file-preview__item__del {
		opacity: 1;
	}
}

.error-red {
	color: var(--Special-Red);
	font-size: 12px;
	font-weight: 300 !important;
	&--position-relative {
		position: relative;
		top: -15px;
		left: 250px;
	}
}

.error-border {
	color: var(--Special-Red) !important;
	border-color: var(--Special-Red) !important;
}

