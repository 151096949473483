.currencyButtonFile {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
	}

	.fileButton {
		display: flex;
		gap: 10px;

		button {
			margin: 0;
		}
	}

	.currencyButton {
		border: none;
		border-radius: 8px;
	}
}

.currencyButton {
	border-radius: 8px;
	border: none;
	background: var(--Grayscale-Gray-80);
	padding: 0;
	display: inline-flex;
	overflow: hidden;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}

	&.currencyButton--full {
		display: grid;
		width: 100%;
		grid-template-columns: (1fr 1fr);

		button, a {
			width: 100%;
		}
	}

	button, a {
		width: 180px;
		height: 56px;
		border-radius: 0;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--Grayscale-White);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px; /* 142.857% */
		border-bottom: 2px solid transparent;
		transition: var(--transition-base);

		@media only screen and (max-width: 575.98px) {
			width: 50%;
		}

		&:hover,
		&.active {
			color: var(--Grayscale-Gray-10);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 142.857% */
			border-color: var(--Blue-Blue-Brand);
			background: transparent;
		}
	}
}
