.account-header {
	.back-btn {
		min-width: 32px;
		width: 32px;
		max-width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: 1px solid var(--Grayscale-Gray-10);
		box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.834 7.99935C13.834 8.27549 13.6101 8.49935 13.334 8.49935L3.31337 8.49935C3.35696 8.57041 3.41309 8.64928 3.48505 8.73888C3.70645 9.01456 4.03365 9.33992 4.51064 9.81268L5.68596 10.9776C5.88209 11.1719 5.8835 11.4885 5.68911 11.6847C5.49472 11.8808 5.17814 11.8822 4.98201 11.6878L3.78553 10.502C3.33488 10.0553 2.96656 9.69029 2.70535 9.36504C2.43373 9.02683 2.23711 8.68353 2.18459 8.27146C2.16156 8.09077 2.16156 7.90792 2.18459 7.72724C2.23711 7.31517 2.43374 6.97187 2.70535 6.63365C2.96656 6.30841 3.33487 5.94338 3.78551 5.49676L4.98201 4.31089C5.17814 4.1165 5.49472 4.11791 5.68911 4.31404C5.8835 4.51017 5.88209 4.82675 5.68596 5.02114L4.51064 6.18601C4.03365 6.65877 3.70645 6.98413 3.48505 7.25982C3.41309 7.34941 3.35696 7.42828 3.31337 7.49935L13.334 7.49935C13.6101 7.49935 13.834 7.72321 13.834 7.99935Z' fill='%23626A85'/%3E%3C/svg%3E");
		background-size: 16px 16px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.title-block-wrap {
		display: flex;
		flex-direction: column;

		.title__user-id {
			padding: 2px 6px;
			min-height: 24px;
			min-width: 100px;
			border-radius: 20px;
			border: 0.8px solid var(--Blue-Blue-Light);
			background: transparent;
			color: var(--Blue-Blue-Light);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
			letter-spacing: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.title,
		.title--type2 {
			font-family: var(--font-sans);
			letter-spacing: 0;
			text-align: left;
			color: var(--Grayscale-White);
			font-size: 16px !important;
			font-style: normal;
			font-weight: 600;
			line-height: 24px !important; /* 150% */
			width: 100%;
		}

		.typeTitle {
			font-size: 38px !important;
			font-weight: 600 !important;
			line-height: 38px !important;
			letter-spacing: 0 !important;
			text-align: left !important;
			margin-bottom: 23px;
		}

		.title__info {
			font-size: 16px !important;
			font-weight: 400 !important;
			line-height: 26px !important;
			letter-spacing: 0 !important;
			text-align: left !important;
			top: calc(100% + 0px) !important;
			width: 100%;
		}
	}
	&__actions {
		display: flex;
		gap: 8px;
		margin-bottom: 8px;
		margin-top: 8px;
	}
}

