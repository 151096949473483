.react-datepicker {
	background: var(--Grayscale-Gray-100);
	padding: 8px;

	&__navigation-icon--previous,
	&__navigation-icon--next {
		background: none;
		left: 0;
	}

	&__navigation--next {
		right: 0px;
		top: -5px;
	}

	&__navigation--previous {
		left: 0px;
		top: -5px;
	}

	&__selected-range {
		display: flex;
		justify-content: space-around;
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 16px;

		p {
			color: var(--Grayscale-White);
			font-size: 12px;
			font-style: normal;
			font-weight: 300;
		}

		svg {
			padding: 6px;
		}

		.selected-range-input {
			border-radius: 6px;
			width: 100px;
			background: var(--Grayscale-Gray-80);
			padding: 6px;
			display: flex;
			justify-content: center;
			color: var(--Grayscale-White);
		}
	}

	&__day-names {
		display: flex;
		justify-content: space-between;
	}
	&__month {
		margin: 14px 0 0 0;
		width: 100%;
	}
	&__day {
		width: 28px;
		height: 28px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background: var(--Grayscale-Gray-30);
		color: var(--Grayscale-Gray-100);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		&:hover {
			border-radius: 4px;
			background: var(--Blue-Blue-Light);
			color: var(--Grayscale-White);
		}
		&--disabled,
		&--outside-month {
			background: var(--Grayscale-Gray-70);
			color: var(--Grayscale-Gray-10);

			&:hover {
				background: var(--Grayscale-Gray-10);
				color: var(--Grayscale-Gray-70);
			}
		}
	}
	&__day--in-range {
		border-radius: 4px;
		background: var(--Blue-Blue-Dark);
		color: var(--Grayscale-White);
		font-weight: 500;
		&::before {
			background: none;
		}
	}
	&__day--keyboard-selected {
		color: var(--Grayscale-Gray-100);
		border-radius: 4px !important;
		font-weight: 500;
	}
	&__current-month {
		font-size: 14px;
		background-color: var(--Grayscale-Gray-100);
		color: var(--Grayscale-White);
		font-weight: 400;
	}
	&__day--range-end {
		background: var(--Blue-Blue-Dark) !important;
		color: var(--Grayscale-White) !important;

		&.react-datepicker__day--in-range {
			border-radius: 4px !important;
		}
		&:hover {
			border-radius: 4px;
			background: var(--Blue-Blue-Light) !important;
			color: var(--Grayscale-White);
		}
	}
	&__day--in-selecting-range {
		background-color: rgba(33, 107, 165, 0.5);
	}
	&__day--in-range {
		+ .react-datepicker__day--in-range:before {
			display: none;
		}
	}
	&__day--selected {
		border-radius: 4px;
		background: var(--Blue-Blue-Dark);
		color: var(--Grayscale-White);
		font-weight: 500;
	}

	&__header {
		button {
			border-radius: 6px;
			background: var(--Blue-Blue-Brand);
			padding: 6px;
			width: 28px;
			height: 28px;
		}
		&--custom {
			background-color: var(--Grayscale-Gray-100);
		}
	}
	&__week {
		display: flex;
		justify-content: space-between;
		gap: 4px;
	}
	&__month {
		width: 100% !important;
		display: flex;
		flex-direction: column;
		gap: 6px;
	}
	&__time-list {
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;
	}
	&__time-list-item {
		&:hover {
			color: var(--Grayscale-Gray-10) !important;
			font-weight: 700;
			background: var(--Grayscale-Gray-70) !important;
		}
	}
	&__time-list-item--selected {
		background: var(--Grayscale-Gray-70) !important;
		color: var(--Grayscale-Gray-10) !important;
		font-weight: 700;
	}
	&__header--time {
		background-color: var(--Grayscale-Gray-100);
	}
	&-time__header {
		color: var(--Grayscale-White);
		font-weight: 600;
	}
	&__time {
		background-color: var(--Grayscale-Gray-100) !important;
		color: var(--Grayscale-Gray-20);
	}
	&__time-container {
		border-left: 1px solid var(--Grayscale-Gray-20);
		width: 95px !important;
	}
}

.react-datepicker__input-container input {
	border-radius: 4px;
	background-color: var(--Grayscale-Gray-100);
	border: none;
	height: 48px;
	color: var(--Grayscale-White);

	&:disabled {
		background-color: var(--Grayscale-Gray-90);
	}
}

