.filter {
	&:last-child {
		@media only screen and (max-width: 767px) {
			margin: 0;
		}
	}
}

.filters {
	&--deposit-wallet-user {
		margin: 0;

		.table-filter {
			margin: 0;
			justify-content: space-between;
			gap: 8px;

			.select {
				.select__current {
					align-items: center;
					justify-content: space-between;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400 !important;
					line-height: 20px; /* 142.857% */
					border-radius: 4px;
					border: 1px solid var(--Grayscale-Gray-80);
					background: var(--Grayscale-Gray-100);
					height: 100%;
				}
			}
		}

		.filter {
			margin-right: 0;
		}
	}

	.filter {
		&--right {
			margin-left: auto;
		}
	}

	&__search-block {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		border: 1px solid var(--Grayscale-Gray-80);
		min-width: 100%;
		min-height: 48px;
		padding: 8px 0px;
		justify-content: space-between;
		margin: 0;
		gap: 8px;

		@media screen and (max-width: 797.98px) {
			padding: 8px 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: flex-start;
		}

		&--bank-accounts-with-select {
			box-shadow: none;
		}

		.filter {
			background: transparent;
			padding: 0;
			border-radius: 0;

			.search-input-wrap {
				@media screen and (max-width: 797.98px) {
					width: 100%;
				}
			}

			.search-input {
				height: 32px;
				display: flex;
				align-items: center;

				@media screen and (max-width: 797.98px) {
					flex-direction: column;
					align-items: stretch;
					height: auto;
					gap: 8px;
				}

				.select__current {
					padding-left: 8px;
					padding-right: 8px;
					min-width: 120px;
					color: var(--Grayscale-White);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: 0;
					text-align: left;
					white-space: nowrap;
				}

				.select__current-arrow {
					background-size: 16px;
					background-repeat: no-repeat;
					width: 16px;
					min-width: 16px;
					height: 16px;
					flex: 0 0 16px;
					max-width: 16px;
				}

				.input {
					margin-left: 8px;

					@media screen and (max-width: 797.98px) {
						margin-left: 0;
					}
				}

				.input-wrapper {
					input {
						height: 32px;
						color: var(--Grayscale-White);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						width: 270px;
						padding: 0 8px;
						border-radius: 4px;
						border: 1px solid var(--Grayscale-Gray-80);
						background: var(--Grayscale-Gray-100);
						transition: border-color var(--transition-base);

						@media screen and (max-width: 797.98px) {
							width: 100%;
						}

						&:focus {
							border-color: var(--Blue-Blue-Brand);
						}

						&::placeholder {
							color: var(--Grayscale-Gray-20);
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							letter-spacing: 0;
							text-align: left;
							opacity: 1;
						}
					}
				}
			}

			.select--type4 {
				height: 32px;
				border-radius: 4px;
				border: 1px solid var(--Grayscale-Gray-80);
				background: var(--Grayscale-Gray-100) !important;
				position: relative;
				transition: border-color var(--transition-base);

				&.active {
					border-color: var(--Blue-Blue-Brand);
				}

				.select__drop {
					display: block;
					padding: 2px;

					ul > li > button {
						padding: 5px 10px;
						border-radius: 4px;
						color: var(--Grayscale-Gray-20);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 160%;
						background: transparent;

						&:hover {
							background: var(--Grayscale-Gray-80);
							color: var(--Grayscale-Gray-10);
						}
					}
				}
			}
		}
	}
}

.filter {
	@media screen and (max-width: 797.98px) {
		margin-top: 0;
	}
}

.active-trade_filters {
	justify-content: space-between;
	padding: 8px 24px;
	flex-direction: row;
	display: flex;
	align-items: center;
	border: none;
	margin-bottom: 8px;
	flex-wrap: wrap;
	border-radius: 6px;
	background: var(--Grayscale-Gray-80);
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);

	@media screen and (max-width: 1199px) {
		justify-content: center;
		gap: 10px;
	}

	&--wrapper {
		display: flex;
		flex-direction: row;
		gap: 40px;
	}

	&--status-wrapper {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	p {
		color: var(--Grayscale-Gray-20);
		font-size: 14px;
	}

	&--left {
		display: flex;
		flex-direction: row;
		gap: 10px;

		@media only screen and (max-width: 797.98px) {
			flex-flow: row wrap;
		}

		@media only screen and (max-width: 575.98px) {
			flex-flow: column nowrap;
			width: 100%;
		}
	}

	.select {
		.select__current {
			align-items: center;
			justify-content: space-between;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400 !important;
			line-height: 20px; /* 142.857% */
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			height: 100%;
		}

		&--date {
			.select__current {
				gap: 8px;
				justify-content: flex-start;
			}
		}
	}

	.select__drop {
		min-width: 110px;
		padding: 2px;
		border-radius: 6px;

		ul > li > button {
			padding: 5px 10px;
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400 !important;
			line-height: 160%;
			min-height: 32px;
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			border-radius: 4px;

			&:hover {
				background: var(--Grayscale-Gray-70);
				color: var(--Grayscale-Gray-20);
			}

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}
		}

		&--date-picker {
			width: 100% !important;
			min-width: 264px;
		}
	}

	&--date,
	&--search {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 0;
		border: none;
		background: transparent;
		gap: 10px;
		height: 32px;
		padding: 2px 8px;
	}

	&--date {
		padding: 0;
		background: none;
		border: 0;

		.btn--date {
			margin-left: 10px;
		}

		.select {
			gap: 10px;
			width: 263px;
			height: 100%;
			align-items: center;
			padding: 0 8px;
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			transition: border-color var(--transition-base), background-color var(--transition-base);

			@media only screen and (max-width: 767.98px) {
				width: 100%;
			}

			&.active {
				background-color: var(--Grayscale-Gray-100);
				border: 1px solid var(--Blue-Blue-Brand);
			}

			&.select--date {
				.select__current {
					border: none;
					border-radius: 0;
					color: var(--Grayscale-Gray-20);
					font-weight: 400 !important;
					height: 100%;
					background: transparent;
				}
			}

			.select__current {
				color: var(--primary-blue-600);
				font-weight: 400 !important;
				height: 100%;
				background: transparent;
			}
		}

		p {
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			display: flex;
		}

		span {
			white-space: pre;
		}
		.react-datepicker {
			justify-content: center;
		}
	}

	&--currency,
	&--status {
		height: 32px;

		.select {
			width: auto;
			min-width: 150px;
			height: 32px;
			border-radius: 4px;
			border: none;
			padding: 0;
			align-items: center;

			&.active {
				border: 0;

				.select__current {
					border-color: var(--Blue-Blue-Brand);
					border-radius: 4px;
				}
			}

			.select__current {
				padding: 2px 8px;
			}
		}

		.selected__current_text {
			display: flex;
			gap: 8px;
			align-items: center;
			p {
				color: var(--Grayscale-White);
			}

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}
		}
	}

	&--search {
		padding: 0;
		border: none;
		p {
			color: var(--greyscale-grey-800);
		}

		.select {
			border: 0;
			border-radius: 4px;
			padding: 0;
			height: auto;
			background: var(--Grayscale-Gray-100) !important;

			.select__current {
				color: var(--Grayscale-White);
				width: 100px;
				padding: 2px 8px;
				min-height: 32px;
				border-radius: 6px;
			}
		}

		.active {
			border: 1px solid var(--Blue-Blue-Brand);
		}

		.search-input {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.input {
			margin-bottom: 0;
			min-width: 110px;

			@media only screen and (max-width: 767.98px) {
				min-width: 100%;
				max-width: 100%;
			}

			.input-item {
				height: 32px;
				color: var(--Grayscale-White);
				border-radius: 4px;
				border: 1px solid var(--Grayscale-Gray-80);
				background: var(--Grayscale-Gray-100);
				transition: border-color var(--transition-base);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				padding: 0 8px;

				&::placeholder {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					color: var(--Grayscale-Gray-20);
				}

				&:focus {
					border: 1px solid var(--Blue-Blue-Brand);
				}
			}

			.input-icon--right {
				right: 10px;
			}
		}

		.phone-number-input {
			grid-template-columns: 1fr 1.8fr;
			align-items: center;
		}

		.phone-number-input-trade {
			display: flex;
			align-items: center;
			gap: 10px;
			p {
				min-width: 40px;
			}
		}
	}
}

.reset_trade-filters {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 2px 8px;
	min-height: 32px;
	border-radius: 4px;
	border: 1px solid var(--Grayscale-Gray-10);
	color: var(--Grayscale-Gray-20);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}

	&:hover {
		border-color: var(--Grayscale-White);
		color: var(--Grayscale-White);

		&:before {
			background-color: var(--Grayscale-White);
		}
	}

	&:before {
		content: '';
		display: block;
		mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_4567_24799)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.17671 0.166997C2.45285 0.168229 2.67571 0.393083 2.67448 0.669223L2.66857 1.99303C2.66616 2.53264 2.6653 2.8951 2.69525 3.16933C2.69562 3.17275 2.696 3.17614 2.69638 3.17951C3.00067 2.84497 3.33619 2.53935 3.69836 2.26725C4.89631 1.36721 6.38624 0.833659 7.99935 0.833659C11.9574 0.833659 15.166 4.04228 15.166 8.00033C15.166 11.9584 11.9574 15.167 7.99935 15.167C4.04131 15.167 0.832684 11.9584 0.832684 8.00033C0.832684 7.72418 1.05654 7.50033 1.33268 7.50033C1.60883 7.50033 1.83268 7.72418 1.83268 8.00033C1.83268 11.4061 4.59359 14.167 7.99935 14.167C11.4051 14.167 14.166 11.4061 14.166 8.00033C14.166 4.59457 11.4051 1.83366 7.99935 1.83366C6.61026 1.83366 5.32974 2.29235 4.29904 3.06674C3.96638 3.31667 3.6598 3.59946 3.38413 3.91028C3.40302 3.91281 3.42276 3.91521 3.44338 3.91746C3.71761 3.94741 4.08006 3.94655 4.61968 3.94414L5.94349 3.93823C6.21963 3.937 6.44448 4.15986 6.44571 4.436C6.44694 4.71214 6.22409 4.93699 5.94795 4.93822L4.59527 4.94426C4.09201 4.94652 3.67228 4.9484 3.33482 4.91155C2.98087 4.8729 2.65464 4.7865 2.37126 4.56719C2.32538 4.53168 2.28127 4.49404 2.23906 4.4544C2.1691 4.3887 2.10438 4.3175 2.04552 4.24145C1.82621 3.95806 1.73981 3.63184 1.70116 3.27789C1.66431 2.94043 1.66619 2.5207 1.66845 2.01744C1.66849 2.00785 1.66854 1.99823 1.66858 1.98857L1.67449 0.664762C1.67572 0.388622 1.90057 0.165765 2.17671 0.166997Z' fill='%23626A85'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4567_24799'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
		background-color: var(--Grayscale-Gray-20);
		width: 16px;
		min-width: 16px;
		height: 16px;
		transition: var(--transition-base);
	}

	svg {
		display: none;
	}
}

.active-message_filters {
	.active-trade_filters--search {
		.select .select__current {
			width: 160px;
		}
		.input {
			max-width: 220px;
		}
	}
}

.message-form_filters {
	display: flex;
	gap: 24px;
	padding: 8px 24px;
	flex-direction: row;
	align-items: center;
	border-radius: 6px;
	background: var(--Grayscale-Gray-80);
	margin-bottom: 10px;
	flex-wrap: nowrap;
	justify-content: space-between;
	@media screen and (max-width: 1180px) {
		flex-wrap: wrap;
	}
	.btn {
		min-height: 44px;
	}

	.search-input-wrapper {
		width: 65%;

		@media screen and (min-width: 1700px) {
			width: 73%;
		}
		@media screen and (min-width: 1500px) and (max-width: 1700px) {
			width: 70%;
		}
		@media screen and (max-width: 1440px) {
			width: 60%;
		}
		@media screen and (max-width: 1320px) {
			width: 50%;
		}
	}
	.input-wrapper {
		display: flex;
		gap: 10px;
		background-color: var(--Grayscale-Gray-80);
		padding: 6px 10px 6px 0;
		align-items: center;

		.select {
			min-width: 170px;
			height: 28px;
			border: none;
			border-radius: 6px;
		}
		.input-item {
			height: 28px;
			border: none;
			border-radius: 6px;
		}
	}
}

