@keyframes popupOpen {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}

@keyframes popupClose {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(0.96) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
}

@keyframes transition {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.popup-window {
	position: static;
}

.popup-content {
	padding: 15px;
	animation: popupOpen 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

	&.is-closing {
		animation: popupClose 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
	}
}

.popup-soc {
	display: flex;
	justify-content: center;
	gap: 16px;
}

.popup-table {
	border-radius: 6px;
	background: var(--Grayscale-Gray-70);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&__tr {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__td {
		color: var(--Grayscale-White);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */

		span {
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px; /* 150% */
		}
	}
}

.popup {
	padding: 16px 24px 24px 24px;
	width: 100%;
	border-radius: 6px;
	background: var(--Grayscale-Gray-80);
	box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.8);
	animation: transition 300ms ease-in-out forwards;

	@media only screen and (min-width: 576px) {
		width: 414px;
	}

	&--width-430 {
		@media only screen and (min-width: 576px) {
			width: 430px;
		}
	}

	&--width-480 {
		@media only screen and (min-width: 576px) {
			width: 480px;
		}
	}

	&--width-520 {
		@media only screen and (min-width: 576px) {
			width: 520px;
		}
	}
	&--width-660 {
		@media only screen and (min-width: 576px) {
			width: 660px;
		}
	}

	&--support {
		width: 100%;
		border-radius: 16px;
		background: var(--Grayscale-Gray-90);

		@media only screen and (min-width: 768px) {
			width: 656px;
		}
	}

	&__close-btn {
		mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2224 6.9089C18.5348 6.59648 18.5348 6.08995 18.2224 5.77753C17.9099 5.46511 17.4034 5.46511 17.091 5.77753L11.9999 10.8686L6.90891 5.77755C6.59649 5.46513 6.08996 5.46513 5.77754 5.77755C5.46512 6.08997 5.46512 6.5965 5.77754 6.90892L10.8686 12L5.77728 17.0912C5.46486 17.4037 5.46486 17.9102 5.77728 18.2226C6.0897 18.535 6.59623 18.535 6.90865 18.2226L11.9999 13.1313L17.0912 18.2226C17.4037 18.535 17.9102 18.535 18.2226 18.2226C18.535 17.9102 18.535 17.4037 18.2226 17.0913L13.1313 12L18.2224 6.9089Z' fill='%23626A85'/%3E%3C/svg%3E");
		background-color: var(--Grayscale-Gray-20);
		display: block;
		width: 24px;
		height: 24px;
		position: absolute;
		top: 18px;
		right: 24px;
		cursor: pointer;
		transition: var(--transition-base);

		&:hover {
			background-color: var(--Grayscale-White);
		}
	}

	.popup-header {
		margin-bottom: 16px;

		&--mb-20 {
			margin-bottom: 20px;
		}

		&__title {
			color: var(--Grayscale-White);
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 140% */

			&.red {
				color: var(--additional-error);
			}

			&.success {
				color: var(--blue700);
				font-size: 24px;
			}

			+ .popup-header__title {
				margin-top: 20px;
				color: var(--blue700);
				text-align: center;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	.popup-body {
		margin-top: 0;

		textarea {
			min-height: 100px;
			padding: 8px 16px;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background-color: #b6b6b6;
			}

			&::-webkit-scrollbar-track {
				border-radius: 2px;
				margin: 10px 5px 10px 5px;
				background: #eeeeee;
			}
		}

		.input-wrapper {
		}
	}

	.popup-icon {
		margin: 20px 0;
		display: flex;
		justify-content: center;
		text-align: center;

		&--type2 {
			margin: 0 0 20px 0;
		}

		&--error {
			position: relative;
			margin-bottom: 20px;
		}
		&--status {
			position: relative;
		}
	}

	.popup-img {
		margin: 0 0 16px 0;
	}

	.popup-text {
		margin-bottom: 28px;
		color: var(--Grayscale-White);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
		letter-spacing: 0;

		&--gray {
			color: var(--Grayscale-Gray-30);
		}

		a,
		a.link {
			color: var(--Blue-Blue-Light) !important;
		}
	}

	.popup-submit {
		margin-top: 32px;
		gap: 16px;

		&--column {
			flex-direction: column;
			align-items: center;
		}
	}
}

.status-popupImg {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.outer-circle {
		width: 52px;
		height: 52px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 1;
	}

	.inner-circle {
		width: 42px;
		height: 42px;

		border-radius: 50%;
	}
}
.popup-body {
	.block {
		background: transparent;
		.outer-circle {
			background-color: #c1e7fd;
		}
		.inner-circle {
			background-color: #3498db;
		}
	}
	.unblock {
		.outer-circle {
			background-color: var(--transparent-green);
		}
		.inner-circle {
			background-color: var(--additional-green);
		}
	}
	.archive {
		.outer-circle {
			background-color: var(--transparent-red);
		}
		.inner-circle {
			background-color: var(--additional-error);
		}
	}
	&--transaction {
		.search-input-wrapper {
			.input-wrapper {
				display: flex;
				flex-direction: row;
				gap: 8px;
			}
			.select {
				height: 48px;
				width: 173px;
				border-radius: 4px;
				background: var(--Grayscale-Gray-100);
			}
			input {
				color: var(--Grayscale-White);
				::placeholder {
					font-family: var(--font-sans);
					font-size: 14px;
				}
			}
			.input-notify {
				padding-left: 120px;
			}
		}

		.input__name,
		.select-block__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}
		.select {
			border-radius: 4px !important;
			height: 48px;

			.select__drop {
				min-width: 120px;
				min-height: 185px;
				.select__drop-item {
					background-color: var(--Grayscale-Gray-100);
					ul li {
						:hover {
							background: var(--Grayscale-Gray-70);
							// color: var(--Grayscale-Gray-10);
						}
					}
					button {
						background-color: var(--Grayscale-Gray-100);
						color: var(--Grayscale-Gray-20);
						:hover {
							background: var(--Grayscale-Gray-70);
							// color: var(--Grayscale-Gray-10);
						}
					}
					.bank-account__name {
						color: var(--Grayscale-White);
					}

					.upper__text {
						display: flex;
						flex-direction: column;
						.coin__text-more {
							font-size: 16px;
							margin-left: 0px;
						}
					}
				}
			}
			.coin__text-more {
				font-size: 16px;
				max-width: 100px;
			}
			.coin--type3 {
				.coin__text {
					color: var(--Grayscale-White);
					margin-bottom: 8px;
				}
				.coin__text-more {
					color: var(--Grayscale-Gray-20);
					max-width: 100%;
				}
			}
			&--add-transaction {
				margin-bottom: 16px;
				.select__current {
					span {
						width: 100%;
						text-align: start;
					}
				}
				.button--size2 {
					margin-top: 10px;
				}
			}
		}
		input,
		textarea {
			color: var(--Grayscale-White);
			font-size: 16px;
		}
		textarea {
			padding-top: 6px;
			min-height: 120px;
		}
		.input-item--auto-calc input {
			color: var(--Grayscale-Gray-20);
			font-weight: 400;
			background-color: var(--Grayscale-Gray-70);
		}
		.input-line {
			margin: 0px;
			margin: 16px 0;
		}
		.input-notify__text {
			color: var(--additional-error);
		}
		.input--error .input-item {
			border-color: var(--additional-error);
		}
		.select__current {
			padding: 8px 12px;
			font-weight: 400;
			font-size: 16px;
			height: 48px;
			border-radius: 4px;
			color: var(--Grayscale-White);
			justify-content: flex-start;
			&-arrow {
				position: absolute;
				right: 12px;
			}
		}
		.select__current--placeholder {
			color: var(--Grayscale-Gray-30);
			font-size: 16px;
			font-weight: 400;
			font-family: var(--font-sans);
			text-align: start;
			min-width: fit-content;
		}
		.input-icon svg path {
			stroke: none;
		}
		.react-datepicker {
			display: grid;
			grid-template-columns: 1.3fr 0.5fr;
			gap: 16px;
			border-color: transparent;
			&__month-container {
				padding: 8px;
			}
			&__navigation--next {
				right: 0px;
				top: -3px;
			}
			&__navigation--previous {
				left: 0px;
				top: -3px;
			}
			&__header--time {
				padding: 5px 8px;
			}
		}
		.react-datepicker__input-container input {
			border-radius: 4px;
			background-color: var(--Grayscale-Gray-100);
			border: none;
			height: 48px;
		}
		.react-datepicker-popper {
			min-width: 350px;
		}
		.user-search__dropdown {
			background-color: var(--Grayscale-Gray-100);
		}
	}
	&--account {
		.select {
			border-radius: 4px;
			height: 44px;
			.select__current--placeholder {
				color: var(--Grayscale-Gray-20);
			}
		}
	}
}
.popup-content {
	.popup-body {
		.popup-img {
			svg {
				//stroke: white !important;
				position: relative;
				z-index: 1;
			}
		}
	}
}

