html body {
	background: var(--body-bg);
	width: 100%;
}

body {
	background: var(--body-bg);
	font-family: var(--font-sans) !important;
}

html body, html body button, html body input, html body textarea {
	font-family: var(--font-sans) !important;
}

