.table-filter {
	border: 1px solid var(--Grayscale-Gray-80);
	min-width: 100%;
	min-height: 64px;
	padding: 8px 24px;
	justify-content: space-between;
	border-radius: 6px;
	background: var(--Grayscale-Gray-80);
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
	margin-top: 8px;
	margin-bottom: 16px;

	@media only screen and (max-width: 767.98px) {
		padding: 8px 12px;
	}

	&.table-filter--reset-request {
		margin: 10px 0 0;
		padding: 10px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.input-wrapper input {
			width: 200px;
		}

		.filter {
			margin: 0;
		}

		.filter__wrap {
			display: flex;
			align-items: center;
			gap: 72px;
		}

		.filter__total {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%; /* 24px */
		}
	}

	.filter {
		&:last-child {
			@media only screen and (max-width: 767.98px) {
				margin: 0;
			}
		}

		.search-input-wrap {
			@media only screen and (max-width: 767.98px) {
				width: 100%;
			}
		}

		.select--type4 {
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100) !important;
			height: 32px;
			position: relative;
			transition: border-color var(--transition-base);

			&.active {
				border-color: var(--Blue-Blue-Brand);
			}

			.select__drop {
				display: block;
				padding: 2px;

				ul > li > button {
					padding: 5px 10px;
					border-radius: 4px;
					color: var(--Grayscale-Gray-20);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%;
					background: transparent;

					&:hover {
						background: var(--Grayscale-Gray-80);
						color: var(--Grayscale-Gray-10);
					}
				}
			}
		}

		.search-input {
			height: 32px;
			display: flex;
			align-items: center;

			@media only screen and (max-width: 767.98px) {
				height: auto;
				flex-direction: column;
				gap: 10px;
				width: 100%;
				align-items: stretch;
			}

			.input--mb-0 {
				margin-left: 8px;
				margin-bottom: 0 !important;

				@media only screen and (max-width: 767.98px) {
					margin-left: 0;
				}
			}

			.select__current {
				padding-left: 8px;
				padding-right: 8px;
				min-width: 90px;
				color: var(--Grayscale-White);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: 0;
				text-align: left;
				white-space: nowrap;
			}
		}
	}

	.filter__wrapper {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}

	.select {
		&.active {
		}
	}

	.input-wrapper {
		input {
			height: 32px;
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			color: var(--Grayscale-White);
			width: 220px;
			padding: 0 10px;
			transition: border-color var(--transition-base);

			@media only screen and (max-width: 767.98px) {
				width: 100%;
			}

			&::placeholder {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: 0;
				text-align: left;
			}

			&:focus {
				border-color: var(--Blue-Blue-Brand);
			}
		}
	}

	.selectedCurrency {
		.select-block {
			&__name {
				margin: 0 10px 0 0;
				color: var(--primary-blue-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.select {
			width: 164px;
			height: 32px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			border-color: var(--Grayscale-Gray-80);
			transition: border-color var(--transition-base);

			&.active {
				border-color: var(--Blue-Blue-Brand);
			}
		}

		.select__drop {
			display: block;
			padding: 2px;
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			overflow: hidden;
			top: calc(100% + 5px);

			ul > li > button {
				padding: 5px 10px;
				border-radius: 0;
				white-space: inherit;
				font-family: var(--font-sans);
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
				display: flex;
				align-items: center;

				&:hover,
				&.active {
					background: var(--Grayscale-Gray-70);
					border-radius: 4px;
				}

				img {
					width: 18px;
					min-width: 18px;
					height: 18px;
					min-height: 18px;
					margin-right: 8px;
				}
			}
		}

		.select__current {
			padding: 0 10px;
		}

		.select__current-text {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}

			p {
				color: var(--Grayscale-White);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}
	}
	&--admin {
		min-height: 48px;
	}

	&--referral {
		min-height: 48px;
	}
}

@media only screen and (max-width: 768px) {
	.title-block-button {
		margin: 5px !important;
	}
}
@media only screen and (max-width: 575.98px) {
}

