.form {
	margin-top: 0;
	width: 100%;
	max-width: 100%;
	background: transparent;
	border: 0;
	border-radius: 0;
	padding: 0;

	&--type2 {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		padding: 24px;
		max-width: 660px;

		.form-body {
			width: 100%;
			max-width: 610px;
			border-radius: 6px;
			margin: 0;
		}

		.input {
			display: flex;
			justify-content: flex-start;
			gap: 10px;
			margin: 0;
			&--row {
				gap: 8px;
				display: flex;
				flex-direction: column;
			}
			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
			}
			.error-red {
				color: var(--Special-Red);
			}
		}

		.input__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input-item {
			height: 48px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			padding: 12px 16px;
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--Grayscale-Gray-20);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
			.error-border {
				border-color: var(--Special-Red);
			}
		}
		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 48px;
			border: none;
			border-radius: 4px;
			.select__custom {
				font-weight: 400;
				font-size: 16px;
				font-family: var(--font-sans);
				color: var(--Grayscale-Gray-20);
				.select-value {
					overflow: visible;
				}
				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}

	&--type3 {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		padding: 24px;

		.form-body {
			width: 100%;
			margin: 0;
		}
		.form-title {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 30px;
			text-align: start;
			margin-bottom: 24px;
		}

		.input {
			margin-bottom: 0;

			&--grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;
			}

			&--row {
				gap: 9px;
				display: flex;
				flex-direction: column;
				justify-content: start;
			}

			&--col-2 {
				display: grid;
				grid-template-columns: 2.2fr 1fr;
				grid-gap: 10px;
				gap: 10px;

				@media screen and (max-width: 767.98px) {
					grid-template-columns: repeat(1, 1fr);
					gap: 0;
					grid-gap: 0;
				}
			}
			.input__label--wrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.input-icon {
				svg path {
					stroke: none;
				}
			}
			.input-wrapper {
				margin-top: 8px;
			}
		}

		.input__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input-item {
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			height: 48px;
			padding: 12px 16px;
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--Grayscale-Gray-20);

				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}

		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 4px;

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);

				.select-value {
					overflow: visible;
				}

				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
		}

		.form__divider {
			margin: 18px 0;
		}
	}

	&--type4 {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
		padding: 16px 24px;

		.form-body {
			width: 100%;
		}

		.input {
			margin: 0 0 16px 0;

			&--row {
				gap: 8px;
				display: flex;
				flex-direction: column;
			}

			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
			}
		}

		.input--error {
			.input-item {
				border-color: var(--additional-error);
			}
		}

		.input-item {
			font-size: 16px;
		}
		.textarea-item {
			height: 100px;
		}

		/*.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 10px;

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);

				.select-value {
					overflow: visible;
				}

				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
		}*/
	}

	.show-pass {
		width: 22px;

		svg path {
			stroke: var(--Grayscale-Gray-20);
		}
	}

	.input--success .input-item {
		border: 1px solid var(--Grayscale-Gray-80);
	}

	.input-wrap {
		&--col-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}
	}

	.select.active {
		border-color: transparent;
	}

	textarea.input-item.input-item--floating {
		padding: 11px 0;
		min-height: 48px;
		resize: vertical;
	}

	.input--error .input-item.input-item--floating {
		border: 0;
		border-bottom: 1px solid var(--ui-error-color);
	}

	.input--success .input-item.input-item--floating {
		border: 0;
		border-bottom: 1px solid var(--Grayscale-Gray-10);
	}
}

